// ─────────────────────────────────────────────────────────────────────────────
// import
// ─────────────────────────────────────────────────────────────────────────────

import React from 'react';
import { Main, Section, H1, P, Link } from '~components';
import { RootContainer } from '~containers';

// ─────────────────────────────────────────────────────────────────────────────
// component
// ─────────────────────────────────────────────────────────────────────────────

export default function ErrorNotFoundPage({ location }) {
  return (
    <RootContainer location={location}>
      <Main gridTemplateColumns="var(--width-outside) 1fr var(--width-outside)" padding="10rem 0 0">
        <Section
          gridColumn="2"
          padding={{
            xs: '5rem 1rem',
            md: '25vh 0 20vh',
            lg: '30vh 0 25vh',
          }}
          textAlign="center"
        >
          <H1
            fontSize={{
              xs: '6rem',
              lg: '10rem',
            }}
            lineHeight="1"
            fontWeight="700"
            letterSpacing="-0.04em"
            margin="0 0 2rem"
          >
            404
          </H1>
          <P fontSize="5rem" lineHeight="5rem" letterSpacing="-0.025em" margin="0 0 4rem">
            Page not found
          </P>
          <P fontSize="2.5rem">
            Safra, tato stránka neexistuje. Ale neztrácej naději—zkus některý s odkazů nahoře v menu
            nebo dole v patičce.
            <br /> A pokud by nepomohly, můžeš se vždy vrátit na{' '}
            <Link to="/" look="primary">
              hlavní stránku
            </Link>
          </P>
        </Section>
      </Main>
    </RootContainer>
  );
}
